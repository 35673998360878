import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup, Container, Modal, ModalBody, ModalFooter, Table} from 'reactstrap';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {Link, useParams} from 'react-router-dom';
import '../../common/Common.css';
import './BudgetDetailList.css';
import {useFetchData} from "../../http/HttpUtil";
import {getBudgetModel} from "../BudgetModel";
import {
    getBudgetDetailEndpoints,
    getBudgetDetailType,
    getBudgetDetailTypeLists,
    getItemStatusAbbreviation,
    getTaskCategories,
    getTaskModel
} from "./BudgetDetailModel";
import {
    calculateNumberOfPeople,
    formatTimestampToDate,
    formatTimestampToDateTime,
    getKey,
    isValidUrl
} from "../../common/Utils";
import AuditResponseWidget from "../audit/AuditResponseWidget";
import Attachment from "../../attachment/Attachment";
import Spinner from "../../common/Spinner";
import LinkCustomers from "../LinkCustomers";
import {useClickBlock} from "../../context/ClickBlockContext";
import {
    deleteBudgetDetail,
    deleteTask,
    duplicateBudgetDetail,
    getBudgetDetail,
    updateBudgetDetail,
    updateFinancing,
    updateTask
} from "../../service/BudgetDetailRestService";
import AssignUserIdModal from "../AssignUserIdModal";
import {assignUser} from "../../service/BudgetRestService";
import BudgetDetailCaseOpening from "./BudgetDetailCaseOpening";
import BudgetDetailCustomers from "./BudgetDetailCustomers";
import BudgetDetailExtra from "./BudgetDetailExtra";
import BudgetDetailAccounting from "./BudgetDetailAccounting";
import BudgetDetailFinancing from "./BudgetDetailFinancing";
import BudgetDetailTask from "./BudgetDetailTask";
import BudgetDetailIncident from "./BudgetDetailIncident";
import BudgetDetailPayment from "./BudgetDetailPayment";

const BudgetDetailList = () => {
    const fetchData = useFetchData();
    const [budgetDetail, setBudgetDetail] = useState(getBudgetModel);
    const [loading, setLoading] = useState(false);
    const [isAccountingEditable, setIsAccountingEditable] = useState(false);
    const [isActivityAccountingEditable, setIsActivityAccountingEditable] = useState(false);
    const [isExtraEditable, setIsExtraEditable] = useState(false);
    const [isFinancingEditable, setIsFinancingEditable] = useState(false);
    const [openAudit, setOpenAudit] = useState(false);
    const [showAttachment, setShowAttachment] = useState(false);
    const [editedAccountingFields, setEditedAccountingFields] = useState({});
    const [editedActivityAccountingFields, setEditedActivityAccountingFields] = useState({});
    const [editedExtraFields, setEditedExtraFields] = useState({});
    const [editedFinancingFields, setEditedFinancingFields] = useState({});
    const [editedTaskFields, setEditedTaskFields] = useState([]);
    const [showLinkCustomers, setShowLinkCustomers] = useState(false);
    const [linkCustomersExpedientCustomerIds, setLinkCustomersExpedientCustomerIds] = useState(null);
    const [isAssignUserModalOpen, setIsAssignUserModalOpen] = useState(false);
    const [inputAssignedUserId, setInputAssignedUserId] = useState('');
    const [assignUserBudgetId, setAssignUserBudgetId] = useState(null);
    const [assignUserExpedientTravelcatId, setAssignUserExpedientTravelcatId] = useState('');
    const [isReorderingMainTypes, setIsReorderingMainTypes] = useState(false);
    const [isReorderingActivityTypes, setIsReorderingActivityTypes] = useState(false);
    const [isReorderingMainTypesAlternatives, setIsReorderingMainTypesAlternatives] = useState(false);
    const [isReorderingActivityTypesAlternatives, setIsReorderingActivityTypesAlternatives] = useState(false);
    const {blockClicks, unblockClicks} = useClickBlock();
    const {id} = useParams();

    const itemStatusAbbreviation = getItemStatusAbbreviation();
    const budgetDetailTypes = getBudgetDetailType();
    const typeLists = getBudgetDetailTypeLists();
    const budgetDetailEndpoints = getBudgetDetailEndpoints();
    const taskCategories = getTaskCategories();

    const convertSubTypeToList = (subTypeData, subType) => {
        if (!subTypeData?.[subType]) {
            return [];
        }

        const subTypeArray = Array.isArray(subTypeData[subType])
            ? subTypeData[subType]
            : [subTypeData[subType]];

        const typeKey = getKey(subType, typeLists);

        return subTypeArray.map((item) => {
            return {
                id: item.id || '',
                itemStatus: getItemStatus(subType, item),
                type: budgetDetailTypes[typeKey],
                dateTime: item.dateTime || '',
                deliveryDateTime: item.deliveryDateTime || '',
                description: getDescriptionByType(subType, item),
                supplier: item.supplier || '',
                price: item.price || '',
                excludeFromPrice: item.excludeFromPrice || '',
                positionInBudget: item.positionInBudget || '',
                notes: item.notes || '',
                reservationCode: item.reservationCode || '',
                splits: item.splits || '',
            };
        });
    };

    const getItemStatusStyle = (itemStatus) => {
        const statusStyles = {
            [itemStatusAbbreviation.BOOKED]: {backgroundColor: 'yellow', color: 'black'},
            [itemStatusAbbreviation.PAYED]: {backgroundColor: 'green', color: 'white'},
            [itemStatusAbbreviation.CANCELLED]: {backgroundColor: 'red', color: 'white'},
        };
        for (const status in statusStyles) {
            if (itemStatus?.startsWith(status)) {
                return {
                    ...statusStyles[status],
                    'box-shadow': 'none',
                };
            }
        }
        return {};
    };

    function getItemStatus(type, item) {
        const abbreviatedItemStatus = itemStatusAbbreviation[item.itemStatus];
        if (type === typeLists.FLIGHT
            && !!item.reservationUntil
            && abbreviatedItemStatus === itemStatusAbbreviation.BOOKED) {
            return `${abbreviatedItemStatus} (hasta el ${formatTimestampToDateTime(item.reservationUntil)})`
        }
        return abbreviatedItemStatus;
    }

    function getDateTime(type, detail) {
        if (type === budgetDetailTypes.FLIGHT
            && !!detail.dateTime) {
            return formatTimestampToDate(detail.dateTime);
        }
        if (type === budgetDetailTypes.ACCOMMODATION
            && !!detail.dateTime
            && !!detail.deliveryDateTime) {
            return `${formatTimestampToDate(detail.dateTime)}-${formatTimestampToDate(detail.deliveryDateTime)}`
        }
        const dateTime = formatTimestampToDateTime(detail.dateTime);
        if (type === budgetDetailTypes.CAR_RENT
            && !!dateTime
            && !!detail.deliveryDateTime) {
            return `${dateTime}-${formatTimestampToDateTime(detail.deliveryDateTime)}`
        }
        return dateTime;
    }

    function getFlightDescription(item) {
        return (
            <span>{`${item.itinerary || ''} | ${item.company || ''} | ${item.reservationCode || ''}`}</span>
        );
    }

    function getAccommodationDescription(item) {
        return (
            <span>{`${item.nights || '0'} | ${item.destination || ''} | ${item.name || ''} | ${item.regime || ''} | ${item.room || ''} | ${item.stars || ''}* | `}
                {isValidUrl(item.link) ? <a href={item.link} target="_blank"
                                            rel="noopener noreferrer">{item.link}</a> : item.link || ''}</span>
        );
    }

    function getCarRentDescription(item) {
        return (
            <span>{`${item.origin || ''} | ${item.destination || ''} | ${item.carModel || ''}`}</span>
        );
    }

    function getTransferDescription(item) {
        return (
            <span>{`${item.origin || ''} | ${item.destination || ''}`}</span>
        );
    }

    function getExcursionDescription(item) {
        return (
            <span>{`${item.name || ''}`}</span>
        );
    }

    function getActivityDescription(item) {
        return (
            <span>{`${item.name || ''} | ${'PVP: ' + item.pvp || ''} | ${'Beneficio: ' + item.benefit || ''}`}</span>
        );
    }

    function getDescriptionByType(type, item) {
        if (type === typeLists.FLIGHT) {
            return getFlightDescription(item);
        } else if (type === typeLists.ACCOMMODATION) {
            return getAccommodationDescription(item);
        } else if (type === typeLists.TRANSFER) {
            return getTransferDescription(item);
        } else if (type === typeLists.EXCURSION) {
            return getExcursionDescription(item);
        } else if (type === typeLists.CAR_RENT) {
            return getCarRentDescription(item);
        } else if (type === typeLists.ACTIVITY) {
            return getActivityDescription(item);
        }
        return (<span/>);
    }

    const duplicate = async (type, id) => {
        const confirmDuplicate = window.confirm('¿Estás seguro de que quieres duplicar este detalle?');

        if (confirmDuplicate) {
            blockClicks();
            const typeKey = getKey(type, budgetDetailTypes);
            const budgetDetailEndpoint = budgetDetailEndpoints[typeKey];
            await duplicateBudgetDetail(fetchData, budgetDetailEndpoint, id)
                .then(() => {
                    unblockClicks();
                    window.location.reload();
                });
        }
    }

    const handleAssignUserFormSubmit = async () => {
        const confirmAssign = window.confirm(`¿Estás seguro de que quieres asignar este presupuesto del expediente ${assignUserExpedientTravelcatId} a ${inputAssignedUserId}?`);

        if (confirmAssign) {
            blockClicks();
            setLoading(true);
            await assignUser(fetchData, assignUserBudgetId, inputAssignedUserId)
                .then(() => {
                    unblockClicks();
                    window.location.reload();
                });
        }
    };

    const handleOpenAssignUserModal = (budgetId, travelcatId) => {
        blockClicks();
        setAssignUserBudgetId(budgetId);
        setAssignUserExpedientTravelcatId(travelcatId);
        setIsAssignUserModalOpen(true);
        unblockClicks();
    };

    const handleCloseAssignUserModal = () => {
        blockClicks();
        setInputAssignedUserId('');
        setAssignUserBudgetId(null);
        setAssignUserExpedientTravelcatId('');
        setIsAssignUserModalOpen(false);
        unblockClicks();
    };

    const remove = async (type, id) => {
        const confirmDelete = window.confirm('¿Estás seguro de que quieres eliminar este detalle?');

        if (confirmDelete) {
            blockClicks();
            const typeKey = getKey(type, budgetDetailTypes);
            const budgetDetailEndpoint = budgetDetailEndpoints[typeKey];
            await deleteBudgetDetail(fetchData, budgetDetailEndpoint, id)
                .then(() => {
                    unblockClicks();
                    window.location.reload();
                });
        }
    }

    useEffect(() => {
        blockClicks();
        setLoading(true);
        getBudgetDetail(fetchData, id)
            .then(data => {
                setBudgetDetail(data);
                setEditedAccountingFields({
                    pvp: data.accounting.pvp,
                    pricePerFamily: data.accounting.pricePerFamily,
                    pricePerFamilyEnabled: data.accounting.pricePerFamilyEnabled,
                    total: data.accounting.total
                });
                setEditedActivityAccountingFields({
                    pvp: data.activityAccounting.pvp,
                    pricePerFamily: data.activityAccounting.pricePerFamily,
                    pricePerFamilyEnabled: data.activityAccounting.pricePerFamilyEnabled,
                    total: data.activityAccounting.total
                });
                setEditedExtraFields({
                    forestPrice: data.extra.forestPrice,
                    insurancePrice: data.extra.insurancePrice,
                    giftPrice: data.extra.giftPrice,
                    cancellationInsurance: data.extra.cancellationInsurance,
                    extras: data.extra.extras?.map(extra => ({
                        id: extra.id,
                        version: extra.version,
                        extraType: extra.extraType,
                        price: extra.price
                    })) || [],
                });
                setEditedFinancingFields({
                    comments: data.financing.comments,
                    schedule: data.financing.schedule,
                    signed: data.financing.signed
                });
                setEditedTaskFields(data.tasks);
                unblockClicks();
                setLoading(false);
            });
    }, [id, setBudgetDetail, fetchData, blockClicks, unblockClicks]);

    const mainSubTypes = [typeLists.FLIGHT, typeLists.ACCOMMODATION, typeLists.TRANSFER, typeLists.CAR_RENT, typeLists.EXCURSION];
    const activitySubTypes = [typeLists.ACTIVITY];

    function getDetails(subTypes, isAlternative) {
        return subTypes.reduce((acc, subType) => {
            return acc.concat(convertSubTypeToList(budgetDetail, subType));
        }, []).filter(item => {
            return (isAlternative && !!item?.excludeFromPrice) ||
                (!isAlternative && !item?.excludeFromPrice);
        });
    }

    function getSortedDetails(subTypes, isAlternative) {
        return [...getDetails(subTypes, isAlternative)].sort((a, b) => a.positionInBudget - b.positionInBudget);
    }

    if (loading) {
        return <Spinner/>;
    }

    function getTitleAndAddBudgetDetailButton() {
        return <div className="d-flex justify-content-between align-items-center">
            <h2>Detalle de presupuesto - {budgetDetail.expedient.travelcatId} - {budgetDetail.title}</h2>
            <div className="float-end">
                <ButtonGroup>
                    <Button size="sm" color="warning"
                            onClick={() => handleShowAttachment()}>Ficheros</Button>
                    <Button size="sm" color="success"
                            onClick={() => handleAuditButtonClick()}>Registros</Button>
                    <Button color="primary" tag={Link} to={"/budgets/" + budgetDetail.id + "/details/new"}>Añadir
                        servicio</Button>
                </ButtonGroup>
            </div>
        </div>;
    }

    function getCaseOpeningTable() {
        return (
            <BudgetDetailCaseOpening
                budgetDetail={budgetDetail}
                loading={loading}
                handleOpenAssignUserModal={handleOpenAssignUserModal}
            />
        );
    }

    function getAssignUserIdModals() {
        return (
            <AssignUserIdModal
                isOpen={isAssignUserModalOpen}
                toggle={handleCloseAssignUserModal}
                handleClose={handleCloseAssignUserModal}
                title="Asignar Usuario"
                description={"Asigna un usuario al presupuesto del expediente " + assignUserExpedientTravelcatId}
                inputAssignedUserId={inputAssignedUserId}
                setInputAssignedUserId={setInputAssignedUserId}
                handleAssignUserFormSubmit={handleAssignUserFormSubmit}
            />
        )
    }

    const handleShowLinkCustomers = (expedient) => {
        setLinkCustomersExpedientCustomerIds(expedient.customers?.map(customer => customer.id) || []);
        setShowLinkCustomers(true);
    };

    const handleCloseLinkCustomers = () => {
        setLinkCustomersExpedientCustomerIds(null);
        setShowLinkCustomers(false);
    };

    function getLinkCustomerModal() {
        return <>
            {showLinkCustomers && (
                <Modal isOpen={showLinkCustomers} toggle={() => handleCloseLinkCustomers()}
                       size="xl">
                    <ModalBody>
                        <LinkCustomers
                            expedientId={budgetDetail.expedient.id}
                            travelcatId={budgetDetail.expedient.travelcatId}
                            initialCustomerIds={linkCustomersExpedientCustomerIds}
                            onClose={() => handleCloseLinkCustomers()}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger"
                                onClick={() => handleCloseLinkCustomers()}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
            )}
        </>;
    }

    function getCustomersTable() {
        return (
            <BudgetDetailCustomers
                budgetDetail={budgetDetail}
                loading={loading}
                handleShowLinkCustomers={handleShowLinkCustomers}
                getLinkCustomerModal={getLinkCustomerModal}
            />
        );
    }

    const handleExtraEdit = () => {
        setIsExtraEditable(true);
    };

    const handleAuditButtonClick = () => {
        setOpenAudit(true);
    };

    const handleShowAttachment = () => {
        setShowAttachment(true);
    };

    const handleExtraInputChange = (field, value) => {
        setEditedExtraFields({...editedExtraFields, [field]: value});
    };

    const handleExtraAccept = () => {
        handleDirectEditAccept({
            ...budgetDetail,
            extra: {
                ...budgetDetail.extra,
                ...editedExtraFields,
            },
        });
    };

    const handleExtraCancel = () => {
        setIsExtraEditable(false);
        setEditedExtraFields({
            forestPrice: budgetDetail.extra.forestPrice,
            insurancePrice: budgetDetail.extra.insurancePrice,
            giftPrice: budgetDetail.extra.giftPrice,
            cancellationInsurance: budgetDetail.extra.cancellationInsurance,
            extras: budgetDetail.extra.extras?.map(extra => ({
                id: extra.id,
                version: extra.version,
                extraType: extra.extraType,
                price: extra.price
            })) || [],
        });
    };

    const handleAddExtra = () => {
        setEditedExtraFields(prevState => ({
            ...prevState,
            extras: [
                ...prevState.extras,
                {extraType: '', price: ''}
            ]
        }));
    };

    const handleRemoveExtra = (indexToRemove) => {
        setEditedExtraFields(prevState => ({
            ...prevState,
            extras: prevState.extras.filter((_, index) => index !== indexToRemove)
        }));
    };

    const handlePriceChange = (index, value) => {
        setEditedExtraFields(prevState => ({
            ...prevState,
            extras: prevState.extras.map((item, i) => i === index ? {...item, price: value} : item)
        }));
    };

    const handleExtraTypeChange = (index, value) => {
        setEditedExtraFields(prevState => ({
            ...prevState,
            extras: prevState.extras.map((item, i) => i === index ? {...item, extraType: value} : item)
        }));
    };

    function getExtraTable() {
        return (
            <BudgetDetailExtra
                budgetDetail={budgetDetail}
                loading={loading}
                isExtraEditable={isExtraEditable}
                handleExtraInputChange={handleExtraInputChange}
                editedExtraFields={editedExtraFields}
                handleExtraTypeChange={handleExtraTypeChange}
                handlePriceChange={handlePriceChange}
                handleRemoveExtra={handleRemoveExtra}
                handleAddExtra={handleAddExtra}
                handleExtraAccept={handleExtraAccept}
                handleExtraCancel={handleExtraCancel}
                handleExtraEdit={handleExtraEdit}
            />
        )
    }

    const handleAccountingEdit = () => {
        setIsAccountingEditable(true);
    };

    const handleAccountingFieldsCancel = () => {
        setIsAccountingEditable(false);
        setEditedAccountingFields({
            pvp: budgetDetail.accounting.pvp,
            pricePerFamily: budgetDetail.accounting.pricePerFamilyEnabled,
            pricePerFamilyEnabled: budgetDetail.accounting.pricePerFamilyEnabled,
            total: budgetDetail.accounting.total
        });
    };

    const handleAccountingFieldsChange = (field, value) => {
        setEditedAccountingFields({...editedAccountingFields, [field]: value});
    };

    function calculateTotalAccounting() {
        if (editedAccountingFields.pricePerFamilyEnabled) {
            return budgetDetail.accounting.pricePerFamily !== editedAccountingFields.pricePerFamily ?
                editedAccountingFields.pricePerFamily :
                editedAccountingFields.total;
        }
        return budgetDetail.accounting.pvp !== editedAccountingFields.pvp ?
            editedAccountingFields.pvp * calculateNumberOfPeople(budgetDetail.people) :
            editedAccountingFields.total;
    }

    const handleAccountingFieldsAccept = () => {
        handleDirectEditAccept({
            ...budgetDetail,
            accounting: {
                ...budgetDetail.accounting,
                ...editedAccountingFields,
                pvp: editedAccountingFields.pricePerFamilyEnabled ? 0 : editedAccountingFields.pvp,
                pricePerFamily: editedAccountingFields.pricePerFamilyEnabled ? editedAccountingFields.pricePerFamily : 0,
                total: calculateTotalAccounting(),
            },
        });
    };

    const handleActivityAccountingEdit = () => {
        setIsActivityAccountingEditable(true);
    };

    const handleActivityAccountingFieldsCancel = () => {
        setIsActivityAccountingEditable(false);
        setEditedActivityAccountingFields({
            pvp: budgetDetail.activityAccounting.pvp,
            pricePerFamily: budgetDetail.activityAccounting.pricePerFamilyEnabled,
            pricePerFamilyEnabled: budgetDetail.activityAccounting.pricePerFamilyEnabled,
            total: budgetDetail.activityAccounting.total
        });
    };

    const handleActivityAccountingFieldsChange = (field, value) => {
        setEditedActivityAccountingFields({...editedActivityAccountingFields, [field]: value});
    };

    function calculateTotalActivityAccounting() {
        if (editedActivityAccountingFields.pricePerFamilyEnabled) {
            return budgetDetail.activityAccounting.pricePerFamily !== editedActivityAccountingFields.pricePerFamily ?
                editedActivityAccountingFields.pricePerFamily :
                editedActivityAccountingFields.total;
        }
        return budgetDetail.activityAccounting.pvp !== editedActivityAccountingFields.pvp ?
            editedActivityAccountingFields.pvp * calculateNumberOfPeople(budgetDetail.people) :
            editedActivityAccountingFields.total;
    }

    const handleActivityAccountingFieldsAccept = () => {
        handleDirectEditAccept({
            ...budgetDetail,
            activityAccounting: {
                ...budgetDetail.activityAccounting,
                ...editedActivityAccountingFields,
                pvp: editedActivityAccountingFields.pricePerFamilyEnabled ? 0 : editedActivityAccountingFields.pvp,
                pricePerFamily: editedActivityAccountingFields.pricePerFamilyEnabled ? editedActivityAccountingFields.pricePerFamily : 0,
                total: calculateTotalActivityAccounting(),
            },
        });
    };

    function handleDirectEditAccept(requestBody) {
        blockClicks();
        updateBudgetDetail(fetchData, requestBody)
            .then(() => {
                unblockClicks();
                window.location.reload();
            });
    }

    function getAccountingTable() {
        return (
            <BudgetDetailAccounting
                price={budgetDetail.accounting.price}
                pvp={budgetDetail.accounting.pvp}
                pricePerFamily={budgetDetail.accounting.pricePerFamily}
                total={budgetDetail.accounting.total}
                benefit={budgetDetail.accounting.benefit}
                loading={loading}
                isAccountingEditable={isAccountingEditable}
                editedAccountingFields={editedAccountingFields}
                handleAccountingFieldsChange={handleAccountingFieldsChange}
                handleAccountingFieldsAccept={handleAccountingFieldsAccept}
                handleAccountingFieldsCancel={handleAccountingFieldsCancel}
                handleAccountingEdit={handleAccountingEdit}
                highlightInYellow={false}
            />
        );
    }

    function getActivityAccountingTable() {
        return (
            <BudgetDetailAccounting
                price={budgetDetail.activityAccounting.price}
                pvp={budgetDetail.activityAccounting.pvp}
                pricePerFamily={budgetDetail.activityAccounting.pricePerFamily}
                total={budgetDetail.activityAccounting.total}
                benefit={budgetDetail.activityAccounting.benefit}
                loading={loading}
                isAccountingEditable={isActivityAccountingEditable}
                editedAccountingFields={editedActivityAccountingFields}
                handleAccountingFieldsChange={handleActivityAccountingFieldsChange}
                handleAccountingFieldsAccept={handleActivityAccountingFieldsAccept}
                handleAccountingFieldsCancel={handleActivityAccountingFieldsCancel}
                handleAccountingEdit={handleActivityAccountingEdit}
                highlightInYellow={true}
            />
        );
    }

    const handleFinancingEdit = () => {
        setIsFinancingEditable(true);
    };

    const handleFinancingInputChange = (field, value) => {
        setEditedFinancingFields({...editedFinancingFields, [field]: value});
    };

    const handleFinancingAccept = () => {
        handleDirectEditAccept({
            ...budgetDetail,
            financing: {
                ...budgetDetail.financing,
                ...editedFinancingFields,
            },
        });
    };

    const handleFinancingCancel = () => {
        setIsFinancingEditable(false);
        setEditedFinancingFields({
            comments: budgetDetail.financing.comments,
            schedule: budgetDetail.financing.schedule,
            signed: budgetDetail.financing.signed
        });
    };

    const handlePaymentStatusChange = (financingPayment, checked) => {
        blockClicks();
        financingPayment.status = checked;
        updateFinancing(fetchData, financingPayment)
            .then(() => {
                unblockClicks();
                window.location.reload();
            });
    };

    function getFinancingTable() {
        return (
            <BudgetDetailFinancing
                budgetDetail={budgetDetail}
                isFinancingEditable={isFinancingEditable}
                editedFinancingFields={editedFinancingFields}
                handlePaymentStatusChange={handlePaymentStatusChange}
                handleFinancingInputChange={handleFinancingInputChange}
                handleFinancingAccept={handleFinancingAccept}
                handleFinancingCancel={handleFinancingCancel}
                handleFinancingEdit={handleFinancingEdit}
                remove={remove}
            />
        );
    }

    function getTaskCategory(item) {
        if (!item) {
            return null;
        }
        return `${taskCategories[item.taskCategory] || item}`;
    }

    const handleAddTask = () => {
        setEditedTaskFields(prevState => ([
            ...prevState,
            getTaskModel()
        ]));
    };

    const handleTaskDelete = (index, taskId) => {
        blockClicks();
        const updatedTasks = [...editedTaskFields];
        updatedTasks.splice(index, 1);
        setEditedTaskFields(updatedTasks);
        if (taskId) {
            deleteTask(fetchData, taskId)
                .then(() => {
                    window.location.reload();
                });
        }
        unblockClicks();
    };

    const handleTaskCategoryChange = (index, category) => {
        const updatedTasks = [...editedTaskFields];
        updatedTasks[index].taskCategory = category;
        setEditedTaskFields(updatedTasks);
    };

    const handleTaskStatusChange = (index, status) => {
        const updatedTasks = [...editedTaskFields];
        updatedTasks[index].status = status;
        setEditedTaskFields(updatedTasks);
    };

    const handleTaskCheck = (task, checked) => {
        blockClicks();
        task.status = checked;
        updateTask(fetchData, task)
            .then(() => {
                unblockClicks();
                window.location.reload();
            });
    };

    const handleTaskAccept = () => {
        blockClicks();
        budgetDetail.tasks = editedTaskFields;
        updateBudgetDetail(fetchData, budgetDetail)
            .then(() => {
                unblockClicks();
                window.location.reload();
            });
    };

    function getTaskTable() {
        return (
            <BudgetDetailTask
                budgetDetail={budgetDetail}
                editedTaskFields={editedTaskFields}
                getKey={getKey}
                handleTaskCheck={handleTaskCheck}
                handleTaskCategoryChange={handleTaskCategoryChange}
                handleTaskAccept={handleTaskAccept}
                handleTaskDelete={handleTaskDelete}
                handleAddTask={handleAddTask}
                getTaskCategory={getTaskCategory}
                handleTaskStatusChange={handleTaskStatusChange}
            />
        );
    }

    function getIncidentTable() {
        return (
            <BudgetDetailIncident
                budgetDetail={budgetDetail}
                getKey={getKey}
                remove={remove}
            />
        );
    }

    function getPaymentTable() {
        return (
            <BudgetDetailPayment
                budgetDetail={budgetDetail}
                getKey={getKey}
                remove={remove}
            />
        );
    }

    function getDateTimeBySplits(detail) {
        return <>
            {detail.splits && detail.splits.length > 0 ? (
                <div>
                    {detail.splits.map((split, index) => (
                        <div key={`split_date_${index}`}>
                            {formatTimestampToDate(split.date)}
                        </div>
                    ))}
                </div>
            ) : getDateTime(detail.type, detail)}
        </>;
    }

    function getDescriptionBySplits(detail) {
        return <>
            {detail.splits && detail.splits.length > 0 ? (
                <div>
                    {detail.splits.map((split, index) => (
                        <div key={`split_description_${index}`}>
                            {split.scheduleChanged ? (
                                <>
                                    <span style={{textDecoration: 'line-through'}}>{split.itinerary}</span> |{' '}
                                    <span style={{
                                        color: 'red',
                                        fontWeight: 'bold'
                                    }}>{split.updatedItinerary}</span> | {split.company}
                                </>
                            ) : (
                                <>
                                    {split.itinerary} | {split.company}
                                </>
                            )}
                        </div>
                    ))}
                </div>
            ) : detail.description}
        </>;
    }

    const onDragEnd = (result) => {
        if (!result.destination || !result.source
            || result.source.index === result.destination.index) return;

        const [typeKey, id] = result.draggableId.split('-');
        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        const subType = typeLists[typeKey];
        if (!subType || !budgetDetail[subType]) {
            return;
        }

        const draggedItem = budgetDetail[subType].find((detail) => detail.id === Number(id));
        if (!draggedItem) {
            return;
        }

        draggedItem.positionInBudget = destinationIndex + 1;
        const updatableList = Object.keys(typeLists).filter(
            (type) =>
                mainSubTypes.includes(typeLists[type]) ||
                activitySubTypes.includes(typeLists[type])
        );

        updatableList.forEach((key) => {
            const listKey = typeLists[key];
            if (
                (mainSubTypes.includes(subType) && mainSubTypes.includes(listKey)) ||
                (activitySubTypes.includes(subType) && activitySubTypes.includes(listKey))
            ) {
                if (sourceIndex < destinationIndex) {
                    budgetDetail[listKey].forEach((detail) => {
                        if (
                            detail.positionInBudget > sourceIndex + 1 &&
                            detail.positionInBudget <= destinationIndex + 1 &&
                            detail.id !== Number(id)
                        ) {
                            detail.positionInBudget -= 1;
                        }
                    });
                } else if (sourceIndex > destinationIndex) {
                    budgetDetail[listKey].forEach((detail) => {
                        if (
                            detail.positionInBudget >= destinationIndex + 1 &&
                            detail.positionInBudget < sourceIndex + 1 &&
                            detail.id !== Number(id)
                        ) {
                            detail.positionInBudget += 1;
                        }
                    });
                }
            }
        });

        setBudgetDetail({...budgetDetail});
    };

    const handleUpdatePositions = () => {
        blockClicks();
        updateBudgetDetail(fetchData, budgetDetail)
            .then(() => {
                unblockClicks();
                window.location.reload();
            });
    };

    function getBudgetDetailsTable(isReordering, setIsReordering, subTypes, isAlternative, title, additionalInfo) {
        const sortedDetails = getSortedDetails(subTypes, isAlternative);

        if (isAlternative && sortedDetails.length === 0) {
            return null;
        }

        function getConcatenatedDetailNotes(detail) {
            return detail.notes ? ' | ' + detail.notes : '';
        }

        function getDraggableRow(detail, provided) {
            return (
                <tr
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <td>{detail.positionInBudget}</td>
                    <td style={getItemStatusStyle(detail.itemStatus)}>{detail.itemStatus}</td>
                    <td>{detail.type}</td>
                    <td>{getDateTimeBySplits(detail)}</td>
                    <td>{getDescriptionBySplits(detail)}</td>
                    <td>
                        {detail.splits && detail.splits.length > 0 ? (
                            <div>
                                {detail.splits.map((split, index) => (
                                    <div key={`split_supplier_${index}`}>
                                        {split.supplier}
                                    </div>
                                ))}
                            </div>
                        ) : detail.supplier}
                    </td>
                    <td>{detail.price}€</td>
                    <td>
                        {detail.reservationCode ? (
                            'CÓDIGO DE RESERVA: ' + detail.reservationCode + getConcatenatedDetailNotes(detail)
                        ) : detail.notes}
                    </td>
                    <td>
                        <ButtonGroup>
                            <Button size="sm" color="warning" tag={Link} disabled={isReordering}
                                    to={`/budgets/${budgetDetail.id}/details/${detail.id}?type=${getKey(detail.type, budgetDetailTypes)}`}>Editar</Button>
                            <Button size="sm" color="success" disabled={isReordering}
                                    onClick={() => duplicate(detail.type, detail.id)}>Duplicar</Button>
                            <Button size="sm" color="danger" disabled={isReordering}
                                    onClick={() => remove(detail.type, detail.id)}>Eliminar</Button>
                        </ButtonGroup>
                    </td>
                </tr>
            );
        }

        function getDraggableTable(detail, index) {
            return <Draggable
                isDragDisabled={!isReordering}
                key={`${getKey(detail.type, budgetDetailTypes)}-${detail.id}`}
                draggableId={`${getKey(detail.type, budgetDetailTypes)}-${detail.id}`}
                index={index}
            >
                {(provided) => getDraggableRow(detail, provided)}
            </Draggable>;
        }

        return <div className="table-container">
            <DragDropContext onDragEnd={onDragEnd}>
                <Table
                    className={`mt-4 table-striped table-hover ${subTypes === activitySubTypes ? 'table-warning' : ''}`}>
                    <thead>
                    <tr>
                        <th colSpan={sortedDetails?.length > 1 ? 8 : 9}>
                            {isAlternative ? `ALTERNATIVAS ${additionalInfo || ''}` : title}
                        </th>
                        {sortedDetails?.length > 1 && (
                            <th className="text-end">
                                {!isReordering ? (
                                    <Button size="sm" onClick={() => setIsReordering(true)}>
                                        Reordenar
                                    </Button>
                                ) : (
                                    <ButtonGroup>
                                        <Button
                                            size="sm"
                                            color="danger"
                                            onClick={() => window.location.reload()}
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            size="sm"
                                            color="success"
                                            onClick={handleUpdatePositions}
                                        >
                                            Guardar
                                        </Button>
                                    </ButtonGroup>
                                )}
                            </th>
                        )}
                    </tr>
                    </thead>
                    <thead>
                    <tr>
                        <th width="2%">Nº</th>
                        <th width="10%">ESTADO</th>
                        <th width="8%">TIPO</th>
                        <th width="10%">FECHA</th>
                        <th width="26%">DESCRIPCIÓN</th>
                        <th width="8%">PROVEEDOR</th>
                        <th width="6%">PRECIO</th>
                        <th width="16%">NOTAS</th>
                        <th width="14%">ACCIONES</th>
                    </tr>
                    </thead>
                    <Droppable droppableId="table-body">
                        {(provided) => (
                            <tbody ref={provided.innerRef} {...provided.droppableProps}>
                            {loading ? (
                                <tr>
                                    <Spinner/>
                                </tr>
                            ) : (
                                sortedDetails.map((detail, index) => getDraggableTable(detail, index))
                            )}
                            {provided.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                </Table>
            </DragDropContext>
        </div>
            ;
    }

    return (
        <div>
            <Container fluid>
                {getTitleAndAddBudgetDetailButton()}
                {getCaseOpeningTable()}
                {getCustomersTable()}
                {getBudgetDetailsTable(isReorderingMainTypes, setIsReorderingMainTypes, mainSubTypes, false, 'SERVICIOS')}
                {getExtraTable()}
                {getAccountingTable()}
                {getBudgetDetailsTable(isReorderingActivityTypes, setIsReorderingActivityTypes, activitySubTypes, false, 'ACTIVIDADES EXTRA')}
                {getActivityAccountingTable()}
                {getBudgetDetailsTable(isReorderingMainTypesAlternatives, setIsReorderingMainTypesAlternatives, mainSubTypes, true)}
                {getBudgetDetailsTable(isReorderingActivityTypesAlternatives, setIsReorderingActivityTypesAlternatives, activitySubTypes, true, '', '- ACTIVIDADES EXTRA')}
                {getPaymentTable()}
                {getIncidentTable()}
                {getFinancingTable()}
                {getTaskTable()}
            </Container>
            {showAttachment && (
                <Attachment
                    entity='expedient'
                    id={budgetDetail.expedient.id}
                    onClose={() => setShowAttachment(false)}
                />
            )}
            {openAudit && (
                <AuditResponseWidget
                    entity='budget'
                    id={budgetDetail.id}
                    onClose={() => setOpenAudit(false)}
                />
            )}
            {getAssignUserIdModals()}
        </div>
    );
};

export default BudgetDetailList;
