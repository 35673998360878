import React, {useEffect, useMemo, useState} from 'react';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import {Button, Container, Form, FormGroup, Input, Label} from 'reactstrap';
import {useFetchData} from "../../http/HttpUtil";
import {
    getAccommodationDictionary,
    getAccommodationModel,
    getActivityDictionary,
    getActivityModel,
    getBudgetDetailType,
    getBudgetDetailTypeLists,
    getBudgetDetailTypeParentLists,
    getCarRentDictionary,
    getCarRentModel,
    getExcursionDictionary,
    getExcursionModel,
    getExtraDictionary,
    getExtraModel,
    getFinancingDictionary,
    getFinancingModel,
    getFlightDictionary,
    getFlightModel,
    getIncidentDictionary,
    getIncidentModel,
    getPaymentCategories,
    getPaymentDictionary,
    getPaymentModel,
    getTaskDictionary,
    getTaskModel,
    getTransferDictionary,
    getTransferModel
} from "./BudgetDetailModel";
import {getBudgetModel} from "../BudgetModel";
import {getDefaultDate, getKey, inList, isNonTechnicalField} from "../../common/Utils";
import CustomSplitInput from "../../common/CustomSplitInput";
import {ClickBlockOverlay} from "../../common/ClickBlockOverlay";
import Spinner from "../../common/Spinner";
import {useClickBlock} from "../../context/ClickBlockContext";
import {getBudgetDetail, updateBudgetDetailByJson} from "../../service/BudgetDetailRestService";
import CharacterCounter from "../../common/CharacterCounter";

const BudgetDetailEdit = () => {

    const fetchData = useFetchData();
    const budgetDetailType = getBudgetDetailType();

    const typeModels = useMemo(() => ({
        FLIGHT: getFlightModel(),
        ACCOMMODATION: getAccommodationModel(),
        TRANSFER: getTransferModel(),
        CAR_RENT: getCarRentModel(),
        EXCURSION: getExcursionModel(),
        ACTIVITY: getActivityModel(),
        PAYMENT: getPaymentModel(),
        FINANCING: getFinancingModel(),
        TASK: getTaskModel(),
        INCIDENT: getIncidentModel(),
        EXTRA: getExtraModel(),
    }), []);

    const typeParentLists = getBudgetDetailTypeParentLists();

    const typeLists = getBudgetDetailTypeLists();

    const typeDictionaries = {
        FLIGHT: getFlightDictionary(),
        ACCOMMODATION: getAccommodationDictionary(),
        TRANSFER: getTransferDictionary(),
        CAR_RENT: getCarRentDictionary(),
        EXCURSION: getExcursionDictionary(),
        ACTIVITY: getActivityDictionary(),
        PAYMENT: getPaymentDictionary(),
        FINANCING: getFinancingDictionary(),
        TASK: getTaskDictionary(),
        INCIDENT: getIncidentDictionary(),
        EXTRA: getExtraDictionary(),
    };

    const paymentCategories = getPaymentCategories();

    const initialFormState = {
        type: budgetDetailType.FLIGHT,
        ...typeModels['FLIGHT'],
        splits: JSON.parse(JSON.stringify(typeModels['FLIGHT'].splits)),
    };

    const [budgetModel, setBudgetModel] = useState(getBudgetModel);
    const [budgetDetail, setBudgetDetail] = useState(initialFormState);
    const navigate = useNavigate();
    const location = useLocation();
    const detailType = new URLSearchParams(location.search).get('type');
    const {id, detailId} = useParams();
    const [loading, setLoading] = useState(false);
    const {blockClicks, unblockClicks} = useClickBlock();

    useEffect(() => {
        blockClicks();
        setLoading(true);
        getBudgetDetail(fetchData, id)
            .then(data => {
                setBudgetModel(data);
                if (detailType) {
                    const typeParentKey = typeParentLists[detailType];
                    const typeListKey = typeLists[detailType];
                    let currentBudgetDetailTypeList;
                    if (!typeParentKey) {
                        currentBudgetDetailTypeList = data[typeListKey];
                    } else {
                        currentBudgetDetailTypeList = data[typeParentKey][typeListKey];
                    }
                    if (detailId !== 'new') {
                        const currentBudgetDetail = currentBudgetDetailTypeList.find(detail => detail.id === parseInt(detailId));
                        setBudgetDetail({
                            type: budgetDetailType[detailType],
                            ...currentBudgetDetail,
                        });
                    } else {
                        setBudgetDetail({
                            type: budgetDetailType[detailType],
                            ...typeModels[detailType],
                        });
                    }
                }
                unblockClicks();
                setLoading(false);
            })
    }, [id, detailId, detailType, typeParentLists, typeLists, budgetDetailType, setBudgetModel, setBudgetDetail,
        typeModels, fetchData, blockClicks, unblockClicks]);

    const handleAddSplit = (splitType) => {
        const newSplit = splitType === budgetDetailType.FLIGHT ?
            {
                date: '',
                itinerary: '',
                company: '',
                supplier: '',
                landNextDay: '',
                scheduleChanged: '',
                updatedItinerary: '',
            } :
            {paymentCategory: getKey(paymentCategories.FLIGHTS, paymentCategories), amount: ''};
        let updatedSplits;
        if (budgetDetail.splits) {
            updatedSplits = [...budgetDetail.splits, newSplit];
        } else {
            updatedSplits = [newSplit];
        }

        setBudgetDetail({
            ...budgetDetail,
            splits: updatedSplits
        });
    };

    const handleRemoveSplit = (index) => {
        const updatedSplits = budgetDetail.splits.filter((_, i) => i !== index);
        setBudgetDetail({
            ...budgetDetail,
            splits: updatedSplits
        });
    };

    const handleChangeSplit = (index, field, newValue) => {
        const updatedSplits = [...budgetDetail.splits];
        updatedSplits[index][field] = newValue;
        setBudgetDetail({
            ...budgetDetail,
            splits: updatedSplits
        });
    };

    const handleChange = (event, typeKey) => {
        const {name, value, type, checked} = event.target;

        function shouldAutoPopulateDeliveryDateTime() {
            return (budgetDetailType[typeKey] === budgetDetailType.ACCOMMODATION ||
                    budgetDetailType[typeKey] === budgetDetailType.CAR_RENT) &&
                name === 'dateTime';
        }

        if (name === 'type') {
            const typeKey = getKey(value, budgetDetailType);
            setBudgetDetail({
                [name]: value,
                ...typeModels[typeKey],
            });
        } else if (type === 'checkbox') {
            setBudgetDetail({
                ...budgetDetail,
                [name]: checked,
            });
        } else if (shouldAutoPopulateDeliveryDateTime()) {
            const deliveryDateTime = new Date(value);
            deliveryDateTime.setDate(deliveryDateTime.getDate() + 1);

            setBudgetDetail({
                ...budgetDetail,
                [name]: value,
                deliveryDateTime: formatDeliveryDateTime(deliveryDateTime, type),
            });
        } else {
            setBudgetDetail({
                ...budgetDetail,
                [name]: value,
            });
        }
    };

    const formatDeliveryDateTime = (date, type) => {
        const newDate = new Date(date);
        if (type === 'date') {
            return newDate.toISOString().split('T')[0];
        } else if (type === 'datetime-local') {
            const year = newDate.getFullYear();
            const month = String(newDate.getMonth() + 1).padStart(2, '0');
            const day = String(newDate.getDate()).padStart(2, '0');
            const hours = String(newDate.getHours()).padStart(2, '0');
            const minutes = String(newDate.getMinutes()).padStart(2, '0');
            return `${year}-${month}-${day}T${hours}:${minutes}`;
        }
        return '';
    };

    function mergeExistingIntoBudgetModel(typeParentKey, typeListKey, existingIndex) {
        budgetModel[typeParentKey][typeListKey][existingIndex] = {...budgetDetail};

        const updatedTypeModel = {
            ...budgetModel[typeParentKey],
            [typeListKey]: [...budgetModel[typeParentKey][typeListKey]],
        };

        return JSON.stringify({
            ...budgetModel,
            [typeParentKey]: updatedTypeModel,
        });
    }

    function mergeNewIntoBudgetModel(typeParentKey, typeListKey) {
        const updatedTypeList = [
            ...budgetModel[typeParentKey][typeListKey],
            {...budgetDetail},
        ];

        const updatedTypeModel = {
            ...budgetModel[typeParentKey],
            [typeListKey]: updatedTypeList,
        };

        return JSON.stringify({
            ...budgetModel,
            [typeParentKey]: updatedTypeModel,
        });
    }

    function mergeExistingIntoBudgetModelWithoutParent(typeListKey, existingIndex) {
        budgetModel[typeListKey][existingIndex] = {...budgetDetail};

        const updatedTypeModel = {
            ...budgetModel,
            [typeListKey]: [...budgetModel[typeListKey]],
        };

        return JSON.stringify(updatedTypeModel);
    }

    function mergeNewIntoBudgetModelWithoutParent(typeListKey) {
        const updatedTypeList = [
            ...budgetModel[typeListKey],
            {...budgetDetail},
        ];

        const updatedTypeModel = {
            ...budgetModel,
            [typeListKey]: updatedTypeList,
        };

        return JSON.stringify(updatedTypeModel);
    }

    function mergeIntoBudgetModel() {
        const typeKey = getKey(budgetDetail.type, budgetDetailType);
        const typeParentKey = typeParentLists[typeKey];
        const typeListKey = typeLists[typeKey];

        let existingIndex;
        if (typeParentKey) {
            existingIndex = budgetModel[typeParentKey][typeListKey].findIndex(item => item.id === budgetDetail.id);
        } else {
            existingIndex = budgetModel[typeListKey].findIndex(item => item.id === budgetDetail.id);
        }

        if (existingIndex !== -1) {
            if (typeParentKey) {
                return mergeExistingIntoBudgetModel(typeParentKey, typeListKey, existingIndex);
            } else {
                return mergeExistingIntoBudgetModelWithoutParent(typeListKey, existingIndex);
            }
        } else if (typeParentKey) {
            return mergeNewIntoBudgetModel(typeParentKey, typeListKey);
        } else {
            return mergeNewIntoBudgetModelWithoutParent(typeListKey);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        blockClicks();

        const jsonBody = mergeIntoBudgetModel();

        await updateBudgetDetailByJson(fetchData, jsonBody);

        setBudgetDetail(initialFormState);
        unblockClicks();
        navigate(`/budgets/${id}/details`);
    };

    const formTitle = <h2>Editar detalle de presupuesto</h2>;

    function isNonCalculatedField(field) {
        return field !== 'nights' &&
            field !== 'benefit' &&
            field !== 'positionInBudget';
    }

    function getDateTimeDefaultValue(field, dateTime) {
        return getDateOrDateTimeDefaultValue(field, dateTime, 'T23:59');
    }

    function getDateDefaultValue(field, dateTime) {
        return getDateOrDateTimeDefaultValue(field, dateTime, '');
    }

    function getDateOrDateTimeDefaultValue(field, dateTime, additionalTime) {
        const defaultDate = getDefaultDateByPreviousField(field, dateTime);
        const finalDefaultDate = getDefaultDate(defaultDate, additionalTime);
        setBudgetDetail({
            ...budgetDetail,
            [field]: finalDefaultDate,
        });
        return finalDefaultDate;
    }

    function getDefaultDateByPreviousField(field, dateTime) {
        let defaultDate = new Date();
        if (field === 'deliveryDateTime' && !!dateTime) {
            defaultDate.setDate(dateTime);
        } else {
            const additionalDate = field === 'deliveryDateTime' ? 1 : 0;
            defaultDate.setDate(defaultDate.getDate() + additionalDate);
        }
        return defaultDate;
    }

    function getValue(field, type) {
        function getDateValueOrEmpty(dateTime) {
            return type === 'date' ? getDateDefaultValue(field, dateTime) : '';
        }

        function getDefaultValue(dateTime) {
            return type === 'datetime-local' ? getDateTimeDefaultValue(field, dateTime) : getDateValueOrEmpty(dateTime);
        }

        return budgetDetail[field] || getDefaultValue(budgetDetail['dateTime']);
    }

    function renderNonSelectFields(typeKey, field) {
        return typeDictionaries[typeKey][field]['type'] === 'split' ? (
            <CustomSplitInput
                splits={budgetDetail[field]}
                splitType={budgetDetailType[typeKey]}
                totalAmount={budgetDetail['total']}
                onAddSplit={handleAddSplit}
                onRemoveSplit={handleRemoveSplit}
                onChangeSplit={handleChangeSplit}
            />
        ) : (
            <>
                <Input
                    type={typeDictionaries[typeKey][field]['type']}
                    name={field}
                    id={field}
                    value={getValue(field, typeDictionaries[typeKey][field]['type'])}
                    onChange={(e) => handleChange(e, typeKey)}
                    autoComplete={field}
                    checked={typeDictionaries[typeKey][field]['type'] === 'checkbox' && !!budgetDetail[field]}
                    required={!typeDictionaries[typeKey][field]['optional'] && typeDictionaries[typeKey][field]['type'] !== 'checkbox' && field !== 'notes'}
                    pattern={typeDictionaries[typeKey][field]['pattern']}
                    title={typeDictionaries[typeKey][field]['error']}
                    maxLength={typeDictionaries[typeKey][field]['maxLength']}
                    min={field === 'deliveryDateTime' ? budgetDetail['dateTime'] : undefined}
                />
                {typeDictionaries[typeKey][field]['maxLength'] && (
                    <CharacterCounter
                        currentLength={getValue(field, typeDictionaries[typeKey][field]['type'])?.length}
                        maxLength={typeDictionaries[typeKey][field]['maxLength']}
                    />
                )}
            </>
        );
    }

    const renderFields = () => {
        const typeKey = getKey(budgetDetail.type, budgetDetailType);
        if (!typeKey) {
            return null;
        }
        const typeModel = typeModels[typeKey];
        return Object.keys(typeModel)
            .filter(field => isNonTechnicalField(field))
            .filter(field => isNonCalculatedField(field))
            .map(field => (
                <FormGroup key={field}>
                    <Label for={field}>{typeDictionaries[typeKey][field]['name']}</Label>
                    {typeDictionaries[typeKey][field]['type'] === 'select' ? (
                        <div className="col-md-2">
                            <Input
                                type="select"
                                name={field}
                                id={field}
                                value={budgetDetail[field]}
                                onChange={handleChange}
                                disabled={typeDictionaries[typeKey][field]['disable']}
                                required
                            >
                                {Object.values(typeDictionaries[typeKey][field]['values']).map((value) => (
                                    <option key={getKey(value, typeDictionaries[typeKey][field]['values'])}
                                            value={getKey(value, typeDictionaries[typeKey][field]['values'])}>
                                        {value}
                                    </option>
                                ))}
                            </Input>
                        </div>
                    ) : renderNonSelectFields(typeKey, field)}
                </FormGroup>
            ));
    };

    if (loading) {
        return <Spinner/>;
    }

    return (
        <div>
            <ClickBlockOverlay/>
            <Container>
                {formTitle}
                <Form onSubmit={handleSubmit}>
                    <FormGroup className="row align-items-center">
                        <Label for="type">Selecciona el tipo</Label>
                        <div className="col-md-2">
                            <Input
                                type="select"
                                name="type"
                                id="type"
                                value={budgetDetail.type}
                                onChange={handleChange}
                                required
                                disabled={!!detailType}
                            >
                                {Object.values(budgetDetailType)
                                    .filter(type => !!detailType ||
                                        inList(type, budgetDetailType.FLIGHT,
                                            budgetDetailType.ACCOMMODATION,
                                            budgetDetailType.CAR_RENT,
                                            budgetDetailType.TRANSFER,
                                            budgetDetailType.EXCURSION,
                                            budgetDetailType.ACTIVITY))
                                    .map(type => (
                                        <option key={type} value={type} style={{
                                            backgroundColor: type === budgetDetailType.ACTIVITY ? 'yellow' : 'inherit'
                                        }}>
                                            {type}
                                        </option>
                                    ))}
                            </Input>
                        </div>
                    </FormGroup>
                    {renderFields()}
                    <FormGroup>
                        <Button color="success" type="submit">Guardar</Button>{' '}
                        <Button color="danger" tag={Link} to={"/budgets/" + id + "/details"}>Cancelar</Button>
                    </FormGroup>
                </Form>
            </Container>
        </div>
    )
};

export default BudgetDetailEdit;
