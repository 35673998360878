import React from 'react';
import {getBudgetDetailType, getPaymentCategories} from "../budget/detail/BudgetDetailModel";
import {Button, Input} from "reactstrap";
import './Common.css';
import {getKey} from "./Utils";
import PropTypes from "prop-types";

const CustomSplitInput = ({splits, splitType, totalAmount, onAddSplit, onRemoveSplit, onChangeSplit}) => {
    const paymentCategories = getPaymentCategories();
    const handleAddSplit = (event) => {
        event.preventDefault();
        onAddSplit(splitType);
    };

    const handleRemoveSplit = (index) => {
        onRemoveSplit(index);
    };

    const handleChangeSplit = (index, field, newValue) => {
        if (field === 'category') {
            newValue = getKey(newValue, paymentCategories);
        }
        onChangeSplit(index, field, newValue);
    };

    const calculateAmountLeft = () => {
        return (totalAmount || 0) - splits?.reduce((acc, split) => acc + Number(split.amount), 0) || 0;
    };

    function renderFlightSplits() {
        return splits?.map((split, index) => (
            <div key={`split_${index}`} style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                <div className="form-group"
                     style={{display: 'flex', alignItems: 'center', marginRight: '10px', width: '125ch'}}>
                    <div style={{marginRight: '10px'}}>
                        {index === 0 && 'Fecha'}
                        <Input
                            type="date"
                            value={split.date}
                            onChange={(e) => handleChangeSplit(index, 'date', e.target.value)}
                            required
                        />
                    </div>
                    <div style={{marginRight: '10px', flexBasis: `${Math.max(split.itinerary.length, 50)}ch`}}>
                        {index === 0 && 'Itinerario'}
                        <Input
                            type="text"
                            value={split.itinerary}
                            onChange={(e) => handleChangeSplit(index, 'itinerary', e.target.value)}
                            autoComplete="itinerary"
                            required
                        />
                    </div>
                    <div style={{marginRight: '10px', flexBasis: `${Math.max(split.company.length, 30)}ch`}}>
                        {index === 0 && 'Aerolínea'}
                        <Input
                            type="text"
                            value={split.company}
                            onChange={(e) => handleChangeSplit(index, 'company', e.target.value)}
                            autoComplete="company"
                            required
                        />
                    </div>
                    <div style={{marginRight: '10px', flexBasis: `${Math.max(split.supplier.length, 20)}ch`}}>
                        {index === 0 && 'Proveedor'}
                        <Input
                            type="text"
                            value={split.supplier}
                            onChange={(e) => handleChangeSplit(index, 'supplier', e.target.value)}
                            autoComplete="supplier"
                            required
                        />
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginRight: '10px',
                        flexBasis: `${Math.max(split.supplier.length, 25)}ch`
                    }}>
                        {index === 0 && 'Aterriza siguiente día'}
                        <Input
                            type="checkbox"
                            checked={split.landNextDay}
                            onChange={(e) => handleChangeSplit(index, 'landNextDay', e.target.checked)}
                        />
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginRight: '10px',
                        flexBasis: `${Math.max(split.supplier.length, 25)}ch`
                    }}>
                        {index === 0 && 'Cambio horario'}
                        <Input
                            type="checkbox"
                            checked={split.scheduleChanged}
                            onChange={(e) => handleChangeSplit(index, 'scheduleChanged', e.target.checked)}
                        />
                    </div>
                    {split.scheduleChanged && (
                        <div style={{
                            marginRight: '10px',
                            flexBasis: `${Math.max(split.updatedItinerary?.length, 50)}ch`
                        }}>
                            {index === 0 && 'Nuevo Itinerario'}
                            <Input
                                type="text"
                                value={split.updatedItinerary}
                                onChange={(e) => handleChangeSplit(index, 'updatedItinerary', e.target.value)}
                                autoComplete="itinerary"
                                required
                            />
                        </div>
                    )}
                </div>
                <Button color="danger" onClick={() => handleRemoveSplit(index)}>
                    Elimina
                </Button>
            </div>
        ));
    }

    function renderPaymentSplits() {
        return (
            <>
                <div style={{color: 'blue'}}><em>Faltan {calculateAmountLeft()}€ por asignar</em></div>
                {splits?.map((split, index) => (
                    <div key={`split_${index}`} style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                        <div className="form-group">
                            <Input
                                type="select"
                                value={split.paymentCategory}
                                onChange={(e) => handleChangeSplit(index, 'paymentCategory', e.target.value)}
                                required
                            >
                                {Object.values(paymentCategories).map((value) => (
                                    <option key={getKey(value, paymentCategories)}
                                            value={getKey(value, paymentCategories)}>
                                        {value}
                                    </option>
                                ))}
                            </Input>
                        </div>
                        <div className="form-group">
                            <Input
                                type="number"
                                value={split.amount}
                                onChange={(e) => handleChangeSplit(index, 'amount', e.target.value)}
                                required
                            />
                        </div>
                        <Button color="danger" onClick={() => handleRemoveSplit(index)}>
                            Elimina
                        </Button>
                    </div>
                ))}
            </>
        );
    }

    const renderSplitInputs = () => {
        const budgetDetailType = getBudgetDetailType();
        if (splitType === budgetDetailType.FLIGHT) {
            return renderFlightSplits();
        }
        return renderPaymentSplits();
    };

    return (
        <div>
            {renderSplitInputs()}
            <Button color="success" onClick={(e) => handleAddSplit(e)}>
                Añade nuevo
            </Button>
        </div>
    );
};

CustomSplitInput.propTypes = {
    splits: PropTypes.array,
    splitType: PropTypes.string.isRequired,
    totalAmount: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,  // Allows empty string
        PropTypes.oneOf([null]) // Allows null
    ]),
    onAddSplit: PropTypes.func.isRequired,
    onRemoveSplit: PropTypes.func.isRequired,
    onChangeSplit: PropTypes.func.isRequired
};

export default CustomSplitInput;
