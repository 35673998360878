import {Button, ButtonGroup, Table} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import {getBudgetDetailType, getPaymentCategories, getPaymentTypeKey} from "./BudgetDetailModel";
import {Link} from "react-router-dom";
import {formatTimestampToDate} from "../../common/Utils";

const BudgetDetailPayment = ({budgetDetail, getKey, remove}) => {

    const budgetDetailTypes = getBudgetDetailType();
    const paymentTypeKeys = getPaymentTypeKey();

    function getPaymentsSort(a, b) {
        if (a.position === b.position) {
            if (a.paymentType === paymentTypeKeys.PAYMENT && b.paymentType !== paymentTypeKeys.PAYMENT) return -1;
            if (a.paymentType !== paymentTypeKeys.PAYMENT && b.paymentType === paymentTypeKeys.PAYMENT) return 1;
            return 0;
        }
        return a.position - b.position;
    }

    function calculatePaymentTotal(payment, total) {
        if (payment.paymentType === paymentTypeKeys.REFUND) {
            return total - payment.total;
        }
        return total + payment.total;
    }

    return (
        <div>
            <Table className="mt-4 table-hover">
                <thead>
                <tr>
                    <td colSpan="3" width="88%"
                        style={{border: '1px solid black', padding: '8px', textAlign: 'center'}}>
                        <strong>PAGOS</strong>
                    </td>
                    <td width="12%" style={{border: '1px solid black', padding: '8px', textAlign: 'center'}}>
                        <Button size="sm" color="primary" tag={Link}
                                to={`/budgets/${budgetDetail.id}/details/new?type=${getKey(budgetDetailTypes.PAYMENT, budgetDetailTypes)}`}>
                            Añadir</Button>
                    </td>
                </tr>
                </thead>
                <tbody>
                {budgetDetail.payments?.slice()
                    .sort((a, b) => getPaymentsSort(a, b))
                    .map(payment => (
                        <tr key={payment.id}>
                            <td style={{
                                border: '1px solid black',
                                padding: '8px',
                                textAlign: 'center'
                            }}>{payment.paymentType === paymentTypeKeys.PAYMENT ? 'PAGO' : 'REEMBOLSO'} {payment.position}</td>
                            <td style={{
                                border: '1px solid black',
                                padding: '8px',
                                textAlign: 'center'
                            }}>{formatTimestampToDate(payment.date)}</td>
                            <td style={{
                                border: '1px solid black',
                                padding: '8px',
                                textAlign: 'center'
                            }}>
                                <strong>{payment.paymentType === paymentTypeKeys.PAYMENT ? '' : '-'}{payment.total}€</strong>
                                <div>
                                    {payment.splits?.map(paymentSplit => (
                                        <div key={paymentSplit.id}>
                                            {getPaymentCategories()[paymentSplit.paymentCategory]} - {paymentSplit.amount}€
                                        </div>
                                    ))}
                                </div>
                            </td>
                            <td style={{border: '1px solid black', padding: '8px', textAlign: 'center'}}>
                                <ButtonGroup>
                                    <Button size="sm" color="warning" tag={Link}
                                            to={`/budgets/${budgetDetail.id}/details/${payment.id}?type=${getKey(budgetDetailTypes.PAYMENT, budgetDetailTypes)}`}>Editar</Button>
                                    <Button size="sm" color="danger"
                                            onClick={() => remove(budgetDetailTypes.PAYMENT, payment.id)}>Eliminar</Button>
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}
                <tr>
                    <td style={{
                        border: '1px solid black',
                        padding: '8px',
                        textAlign: 'center'
                    }}>TOTAL
                    </td>
                    <td style={{
                        border: '1px solid black',
                        padding: '8px',
                        textAlign: 'center'
                    }}><em>Último pago: {' '}
                        {budgetDetail.payments && budgetDetail.payments.length > 0 && (
                            new Date(Math.max(...budgetDetail.payments
                                .filter(payment => payment.paymentType === paymentTypeKeys.PAYMENT)
                                .map(payment => new Date(payment.date).getTime()))).toLocaleDateString()
                        )}</em>
                    </td>
                    <td style={{
                        border: '1px solid black',
                        padding: '8px',
                        textAlign: 'center'
                    }}>
                        <em>{budgetDetail.payments.reduce((total, payment) => calculatePaymentTotal(payment, total), 0)}€</em>
                    </td>
                    <td style={{
                        border: '1px solid black',
                        padding: '8px',
                        textAlign: 'center'
                    }}><em>Queda para el total: {' '}
                        {budgetDetail.accounting.total + budgetDetail.activityAccounting.total - budgetDetail.payments.reduce((total, payment) => calculatePaymentTotal(payment, total), 0)}€
                    </em></td>
                </tr>
                </tbody>
            </Table>
        </div>
    );
};

BudgetDetailPayment.propTypes = {
    budgetDetail: PropTypes.shape({
        id: PropTypes.any.isRequired,
        payments: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.any.isRequired,
                paymentType: PropTypes.any.isRequired,
                date: PropTypes.any.isRequired,
                total: PropTypes.any.isRequired,
                splits: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.any.isRequired,
                        paymentCategory: PropTypes.any.isRequired,
                        amount: PropTypes.any.isRequired,
                    }))
            })),
        accounting: PropTypes.shape({
            total: PropTypes.any.isRequired
        }),
        activityAccounting: PropTypes.shape({
            total: PropTypes.any.isRequired
        })
    }).isRequired,
    getKey: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired
};

export default BudgetDetailPayment;
